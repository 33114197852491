import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import AboutIntro from "../../components/aboutIntro"
import SocialShift from "../../components/socialShift"

const mlNext = ({ data, location }) => {
  const issues = data.allSanityMlNextPage.edges[0].node.issue.reverse()

  return (
    <Layout scheme="dark" location={location} pageCategory="agency">
      <Seo title="MullenLowe U.S. — Next" />
      <AboutIntro copy={data.allSanityMlNextPage.edges[0].node._rawIntro} />
      <div className="container">
        <div className="social-shift__issues ml-next__issues">
          {issues.map(issue => (
            <SocialShift
              key={issue.title}
              title={issue.title}
              copy={issue._rawCopy}
              image={issue.image}
              pdfLocation={issue.pdf.asset.url}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityMlNextPage {
      edges {
        node {
          _rawIntro
          issue {
            _rawCopy
            title
            image {
              ...ImageWithPreview
            }
            pdf {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default mlNext
