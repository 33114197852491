import * as React from 'react'
import { PortableText } from '@portabletext/react'
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from 'prop-types'

const SocialShift = ({ title, copy, image, pdfLocation }) => {
  return (
    <section className="social-shift dark">
      <div className="social-shift__img-wrap">
        <SanityImage {...image} alt="social shifts cover image" />
      </div>
      <div className="social-shift__content">
        <h2 className="social-shift__title">{title}</h2>
        <PortableText value={copy} />
        <a className="social-shift__link" href={pdfLocation} target="_blank" rel='noreferrer'>Download PDF</a>
      </div>

    </section>
  )
}

SocialShift.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.array,
  image: PropTypes.object.isRequired,
  pdfLocation: PropTypes.string.isRequired
}

export default SocialShift